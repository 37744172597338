.responseBtns {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.responseBtns button {
	width: 175px;
	padding: 25px 15px;
	margin: 30px;
	font-size: 1.6rem;
	font-weight: bold;
	border-radius: 20px;
	border-style: none;
	transition: transform 0.35s;
	color: white;
	background-color: #e82828;
}

.responseBtns button:hover {
	cursor: pointer;
	transform: scale(1.05);
}

@media screen and (max-width: 768px) {
	.responseBtns button {
        font-size: 1.4rem;
		flex-basis: 39%;
		margin: 20px;
	}
}
