.light {
	background-color:#FFFFFF;
    color: black;
}

.footer {
    text-align: center;
    overflow: hidden;
    margin: 1rem;
    color: white;
}

.footer p {
    font-size: 1.05rem;
}

.themeBtn:hover {
    cursor: pointer;
}

a {
    cursor: pointer;
    color: #E82828;
    font-weight: bold;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}