/* Inserting a collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
	flex-basis: 100%;
	height: 0;
}

/* Use a collapsed column to break to a new column */
.break-column {
	flex-basis: 100%;
	width: 0;
}

.logoContainer {
	display: flex;
	align-items: center;
    justify-content: center;
}

.logo {
    height: 175px;
	margin: 2rem;
}

.rotate {
	-webkit-animation: rotate 20s linear infinite;
	-moz-animation: rotate 20s linear infinite;
	animation: rotate 20s linear infinite;
  }

@-webkit-keyframes rotate {
	100% {
		-webkit-transform: rotate(1turn);
	}
}

@-moz-keyframes rotate {
	100% {
		-moz-transform: rotate(1turn);
	}
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@media screen and (max-width: 768px) {
	.logo {
		height: 125px;
	}
}