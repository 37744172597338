.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    overflow: hidden;
}

.questionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5rem;
    color: white;
    background-color: #191919;
    border-radius: 20px;
    padding: 20px;
    width: 50%;
}

.questionContainer.light {
    background-color: #F8F8F8;
}

.questionNum {
    font-weight: bold;
}

.question {
    width: 80%;
}

.link {
    font-size: 1.15rem;
    margin: 5px;
}

@media screen and (max-width: 768px) {
    .questionContainer {
        padding: 1.75rem;
        width: 90%;
    }
    
    .question {
        font-size: 1.25rem;
        width: 100%;
    }
}